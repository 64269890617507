import {Button, Tooltip, message} from "antd";
import React, {useState} from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {EyeInvisibleOutlined, EyeOutlined, LockOutlined} from "@ant-design/icons";
import {toastClick} from "../toast/CustomToast";

export function Schedule({path, text=""}) {
    const {accessToken} = useSelector(state => state.authUser);
    const[loading, setLoading] = useState(false);
    const returnValue = <i aria-hidden="true"><LockOutlined /></i>;

    const onChange =async ()=>{
        setLoading(true);
        await axiosInstance(accessToken).put(
            path
        ).then((result)=> {
            toastClick("Mise a jour effectuee","success")
            window.location.reload(true);
            setLoading(false);
        }).catch((result)=>{
            toastClick(result.response.data.message,'error')
            setLoading(false);
        });
    }
    return (
        <>
            <Tooltip title={text.trim().toUpperCase()}>
                <Button disabled={loading} loading={loading} type="default" shape="circle"  onClick={onChange} >
                    {returnValue}
                </Button>
            </Tooltip>
        </>
    )
}