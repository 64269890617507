import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import React, {useEffect, useState} from "react";
import {Button, Col, Empty, message, Row, Spin} from "antd";
import {useRole, useTitle} from "../../../components/hook";
import {useSelector} from "react-redux";
import BreadCom from "../../../components/breadCom/BreadCom";

export default function ViewValeur() {
    const {accessToken} = useSelector(state => state.authUser);
    const params = useParams();
    const nav = useNavigate();
    const [mission, setMission] = useState(null);
    const [loading, setLoading] = useState(true);
    useRole(['ROOT','SUPERADMIN']);
    useTitle('View Valeur');
    const navigation = (link)=>{
        setLoading(true);
        nav(link);
        setLoading(false);
    }
    const getMissionById = () => {
        axiosInstance(accessToken).get('api/valeur/find-by-id/'+parseInt(params.id))
            .then(
                (response)=>{
                    setMission(response.data);
                    setLoading(false);
                }
            ).catch(
            (error)=>{
                message.error(error.response.data.message);
                setLoading(false);
            }
        )
    }

    useEffect(
        ()=>{
            setLoading(true)
            getMissionById();
        },[]
    )
    const returnValue = mission ==null ? <>
        <Spin spinning={loading}>
            <Empty />
        </Spin>
    </>  : <>
        <Spin  spinning={loading}>
            <div>
                <Row >
                    <Col>
                        <Row>
                            <Col style={{marginTop: '5px'}}>
                                <Button type="primary" onClick={()=>{navigation('/admin/valeur/update/'+mission.id)}}>Edit</Button>
                            </Col>
                        </Row>


                    </Col>
                </Row>
                <Row>
                    <Col  >
                        <Row>
                            <Col >
                                <strong>Titre :</strong> <span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{mission.title}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <strong>Description :</strong><span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{mission.description == null ? "null" : mission.description}</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Spin>
    </>

    return(
        <>
            <BreadCom name={"Valeur info"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Detail
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        {returnValue}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}