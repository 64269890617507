import {useSelector} from "react-redux";
import {useState} from "react";
import {Button, DatePicker, Form, Input, Modal, Select, Space, Spin, Tag} from "antd";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {toastClick} from "../../../components/toast/CustomToast";
import {PlusCircleFilled} from "@ant-design/icons";

export default function AddSondage() {
    const {accessToken, user} = useSelector(state => state.authUser);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [reponses, setReponses] = useState([]);
    const [typeUsers, setTypeUsers] = useState(['USER', 'ANONYMOUS', 'FORMATION', 'ALL']);
    const [domains, setDomains] = useState(['SANTE_SEXUELLE',
        'PLANNING_FAMILLIAL',
        'HYGENE_MENSTRUELLE',
        'GROSSESSE_PRECOSE',
        'VIH_IST',
        'DROGUE_STUPEFIANT',
        'IVG',
        'VVG',
        'Autres']);
    const [form] = Form.useForm();

    const [quizzName, setQuizzName] = useState(''); // État pour le nom du quiz
    const [response, setResponse] = useState(''); // État pour une réponse unique

    const handleQuizzNameChange = (event) => {
        setQuizzName(event.target.value);
    };

    const handleResponseChange = (event) => {
        setResponse(event.target.value);
    };

    const addResponseInQuestion = () => {
        if (response.trim() !== '') {
            let listOfData = reponses;
            let data = {
                id: 0,
                value: response
            };
            listOfData.push(data)
            setReponses(listOfData);
            setResponse('');
            form.resetFields(['response']);
        }
    };

    const addQuestion = ()=>{
        let libelle = form.getFieldInstance("quizzname").input.value;
        if(libelle.toString().trim().length !== 0){
            let listQuestions = questions;
            let question = {
                id: 0,
                typeResponse: "BINAIRY",
                modelResponses: reponses,
                libelle: libelle,
                position: 0
            };
            listQuestions.push(question);
            setQuestions(listQuestions);
            setVisible(false);
            form.resetFields(['response']);
            form.resetFields(['quizzname']);
            setReponses([]);
        }
    }
    const openModal =()=>{
        form.resetFields();
        setLoading(false);
        setVisible(false);
        setReponses([]);
        setQuestions([]);
        setModalAddOpen(true);
    }

    const onSubmit = (values) => {
        setLoading(true);
        let dat =      {
            id: null,
            name: values.name,
            createUser: user.code,
            scheduler: null,
            questions: questions,
            state: "CREATED",
            domain: [
                {
                    id: 0,
                    domain: values.domain
                }
            ],
            typeUser: values.typeUser
        };
        axiosInstance(accessToken).post(
            "test/api/sondage/save",
            dat,
        ).then(
            (result)=> {
                toastClick('Sondage '+dat.nom+' Create','success');
                form.resetFields();
                setLoading(false);
                setTimeout(
                    ()=>{
                        window.location.reload();
                        setModalAddOpen(false);
                    },3000
                )
            }).catch((result)=> {
            toastClick(JSON.stringify(result),'error');
            setLoading(false);
        });
    };


    return(
        <>
            <Button style={{float: "right"}}  onClick={openModal} >
                <i aria-hidden="true"><PlusCircleFilled /></i>Ajouter
            </Button>
            <Modal
                title="Creation d'un Sondage"
                style={{
                    top: 20,
                }}
                width={1000}
                open={modalAddOpen}
                maskClosable={false}
                closable={false}
                footer={[]}
            >
                <Spin spinning={loading}>
                    <Form onFinish={onSubmit} form={form}>
                        <Form.Item
                            label="Nom"
                            name="name"
                            rules={
                                [
                                    { required: true, message: 'Veuillez entrer le nom' },
                                    {type:"string", message:"Entrer un nom valide !"}
                                ]}
                            hasFeedback>
                            <Input placeholder="entrer le nom"/>
                        </Form.Item>
                        <Form.Item
                            label="Type d'utilisateur cible"
                            name="typeUser"
                            rules={
                                [
                                    { required: true, message: 'Veuillez selectionner le public cible' },
                                    {type:"string", message:"Selectionner le public cible !"}
                                ]}
                            hasFeedback>
                            <Select placeholder="Choisir le Type d'utilisateur cible">
                                {
                                    typeUsers.map(typeUser => {
                                        return <Select.Option key={typeUser} value={typeUser}>{typeUser}</Select.Option>;
                                    })

                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Domain"
                            name="domain"
                            rules={
                                [
                                    { required: true, message: 'Veuillez selectionner le Domaine' },
                                    {type:"string", message:"Selectionner le Domaine !"}
                                ]}
                            hasFeedback>
                            <Select placeholder="Choisir le Type d'utilisateur cible">
                                {
                                    domains.map(domain => {
                                        return <Select.Option key={domain} value={domain}>{domain=='VVG'?"VGB":domain}</Select.Option>;
                                    })

                                }
                            </Select>
                        </Form.Item>
                        <>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <section className="col-lg-12">
                                            <div className="card card-primary">
                                                <div className="card-header">
                                                    <h3 className="card-title">
                                                        <i className="fas fa-chart-pie mr-1"></i>
                                                        Questions <small>[ {questions.length} questions ajoutée]</small>
                                                    </h3>
                                                </div>
                                                <div className="card-body">
                                                    {visible === false ? (
                                                        <Button onClick={() => setVisible(true)}>Ajouter les questions</Button>
                                                    ) : (
                                                        <div className="tab-content p-0">
                                                            <Form.Item
                                                                label="Nom"
                                                                name="quizzname"
                                                                rules={[
                                                                    { required: true, message: 'Veuillez entrer le libellé' },
                                                                    { type: 'string', message: 'Entrer un libellé valide !' },
                                                                ]}
                                                                hasFeedback
                                                            >
                                                                <Input
                                                                    placeholder="Entrer le libellé de la question"
                                                                    value={quizzName}
                                                                    onChange={handleQuizzNameChange}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item label="Réponse Possible">
                                                                <div className="row">
                                                                    <section className="col-lg-6">
                                                                        <div className="card card-primary">
                                                                            <div className="card-header">
                                                                                <h3 className="card-title">
                                                                                    <i className="fas fa-chart-pie mr-1"></i>
                                                                                    Ajouter
                                                                                </h3>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="tab-content p-0">
                                                                                    <Form.Item
                                                                                        label="Réponse"
                                                                                        name="response"
                                                                                        rules={[
                                                                                            { required: true, message: 'Veuillez entrer la réponse à votre question' },
                                                                                        ]}
                                                                                        hasFeedback
                                                                                    >
                                                                                        <Input
                                                                                            placeholder="Entrer la réponse"
                                                                                            value={response} // Liaison bidirectionnelle
                                                                                            onChange={handleResponseChange} // Met à jour l'état
                                                                                        />
                                                                                    </Form.Item>
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={addResponseInQuestion}
                                                                                        className="btn btn-secondary"
                                                                                    >
                                                                                        Add
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                    <section className="col-lg-6">
                                                                        <div className="card card-primary">
                                                                            <div className="card-header">
                                                                                <h3 className="card-title">
                                                                                    <i className="fas fa-chart-pie mr-1"></i>
                                                                                    Liste des Réponses
                                                                                </h3>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="tab-content p-0">
                                                                                    {reponses.length === 0 ? (
                                                                                        <p>Aucune réponse ajoutée</p>
                                                                                    ) : (
                                                                                        reponses.map((reponse, index) => (
                                                                                            <p
                                                                                                title={reponse}
                                                                                                style={{ cursor: 'pointer' }}
                                                                                                color={'green'}
                                                                                                key={index}
                                                                                            >
                                                                                                {reponse.value}
                                                                                            </p>
                                                                                        ))
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                                <button className="btn btn-primary" onClick={addQuestion}>Ajouter la question</button>
                                                            </Form.Item>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </section>
                        </>
                        <Form.Item>
                            <Space style={{float:'right'}}>
                                <Button type='primary' htmlType='submit' disabled={loading}>
                                    Submit
                                </Button>
                                <Button danger  disabled={loading}  onClick={() => setModalAddOpen(false)} >
                                    <i className="fa fa-times" aria-hidden="true"></i> cancel
                                </Button>

                            </Space>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    );

}
/*

{
    "id": 302,
    "typeResponse": "BINAIRY",
    "modelResponses": [
    {
        "id": 153,
        "value": "Reponse 1"
    },
    {
        "id": 154,
        "value": "Reponse 3"
    },
    {
        "id": 152,
        "value": "Reponse 2"
    }
],
    "libelle": "Question 2",
    "position": 0
},*/
