import React, {useEffect, useState} from "react";
import {Button, Space, Tag, Tooltip} from "antd";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {useRole, useTitle} from "../../../components/hook";
import {EditFilled, InfoCircleFilled} from "@ant-design/icons";
import BreadCom from "../../../components/breadCom/BreadCom";
import TableTemplate from "../../../components/table";
import AddTheme from "../add/AddTheme";
import {AddQuizzToFormation} from "../../../components/addQuizzToFormation/AddQuizzToFormation";
import {
    SetQuizzToFormationQuestionResponse
} from "../../../components/setQuizzToFormationQuestionResponse/SetQuizzToFormationQuestionResponse";
import {Active} from "../../../components/active";

export default function ListTheme() {
    const {accessToken} = useSelector(state => state.authUser);
    const [loading, setLoading] = useState(true);
    const nav = useNavigate();
    const [theme, setTheme] = useState([]);
    const items = [];
    useRole(['ROOT', 'SUPERADMIN']);
    useTitle('Liste des Themes');
    const navigate = (link) => {
        nav(link);
    }
    const fetchThemes = () => {
        setLoading(true);
        axiosInstance(accessToken).get('api/theme/admin').then(
            (response) => {
                setTheme(response.data);
                setLoading(false);
            }
        ).catch((error) => {
            setLoading(false);
        });
    }
    useEffect(() => {
        fetchThemes();
    }, []);
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        }, {
            title: 'Libelle',
            key: 'title',
            render: (_, record) => (
                <>
          <span>
            {record.title}
          </span>
                </>
            ),
        }, {
            title: 'Status',
            key: 'active',
            render: (_, record) => {
                return (
                    <Tag title={record.title} style={{cursor: 'pointer'}}  color={record.active ? "green":"volcano"}  key={record.id} >
                                {record.active ? "Active":"Desactive"}
                            </Tag>
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            render:  (_, record) => {
                return  (
                    <>
                        <Space>
                            <Active status={record.active} path={"api/theme/"} id={record.id}/>
                        </Space>
                    </>
                )
            }
        },
    ];

    return(
        <>
            <BreadCom name={"Liste des Themes"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Liste des Themes
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <TableTemplate columns={columns} loading={loading} addBTN={<AddTheme />} datas={theme} />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}