import React, {useEffect, useState} from 'react';
import {Tag, Button, Space, Tooltip} from 'antd';
import "./ListArticle.css";
import { useSelector } from "react-redux";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import TableTemplate from "../../../components/table/index";
import {Active} from "../../../components/active/index";
import {useNavigate} from "react-router-dom";
import {useRole, useTitle} from "../../../components/hook/index";
import {EditFilled, InfoCircleFilled} from "@ant-design/icons";
import BreadCom from "../../../components/breadCom/BreadCom";
import AddArticle from "../add";

export default function ListArticle() {
    useRole(['ROOT','SUPERADMIN']);
    useTitle('Liste des Articles');
  const [current, setCurrent] = useState(0);
  const {accessToken} = useSelector(state => state.authUser);
  const nav = useNavigate();
  const [articles, setAtricles] = useState([]);
  const [loading, setLoading] = useState(true);
    const navigate= (link)=>{
        nav(link);
    }
  const columns = [
    {
        title: '#',
        dataIndex: 'id',
        key: 'key',
    },
      {
        title: 'Titre',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Author',
        dataIndex: 'author',
        key: 'author',
      },
      {
        title: 'Status',
        key: 'status',
        render:  (_, record) =>{
          let data = record.status ? (<>
                <Tag color="green" key={record.id}>
                  Actif
                </Tag>
              </>) : (
                  <>
                    <Tag color="volcano" key={record.id}>
                      Inactif
                    </Tag>
                  </>
          )
          return (
              <>
                {data}
              </>
          );
        }
      },
      {
        title: 'Lue',
        key: 'lue',
        render:  (_, record) =>{
          let data = record.lue > 0  ? (<>
                <Tag color="green" key={record.id}>
                  {record.lue} Fois
                </Tag>
              </>) : (
                  <>
                    <Tag color="volcano" key={record.id}>
                    0 Fois
                    </Tag>
                  </>
          )
          return (
              <>
                {data}
              </>
          );
        }
      },
      {
        title: 'Action',
        key: 'action',
        render:  (_, record) => {

          return (
            <>
            <Space>
                <Tooltip title={"Detail sur l'article"}>
                    <Button type="primary" shape="circle"  onClick={() => navigate('/admin/article/'+record.id)} >
                        <i aria-hidden="true"><InfoCircleFilled /></i>
                    </Button>
                </Tooltip>
                <Tooltip title={"Mise a jour d'un Article"}>
                    <Button type="primary" shape="circle"  onClick={() => navigate('/admin/article/update/'+record.id)} >
                        <i aria-hidden="true"><EditFilled /></i>
                    </Button>
                </Tooltip>
                <Active status={record.status} path={"api/article/active/"} id={record.id}/>
            </Space>
            </>
          );
        },
      },
    ];
    const fetchArticlesByCategory = (categorie, color)=>{
        setLoading(true);
        axiosInstance(accessToken).get('api/article/get-by-categorie/'+categorie).then(
            (response)=>{
                setCurrent(color);
                setAtricles(response.data);
                setLoading(false);
            }
        ).catch( (error)=>{
            setLoading(false);
        });
    }
    const fetchArticles = ()=>{
        setLoading(true);
        setCurrent(0)
      axiosInstance(accessToken).get(
        "api/article"
      ).then((result)=> {
        setAtricles(result.data)
        setLoading(false);
      }).catch((result)=> {
          setLoading(false);
      });
    }
    useEffect(()=>{
      fetchArticles()
    },[]);


    return(
        <>
            <BreadCom name={"Liste des Articles"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Liste des Articles
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <div style={{margin: '15px'}}>
                                            <Tag
                                                title={"Tous les Temoignage"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 0 ? "green" : "blue"}
                                                key={'all'}
                                                onClick={()=>fetchArticles()}>
                                                Tous
                                            </Tag>
                                            <Tag
                                                title={"Sante Sexuel"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 1 ? "green" : "blue"}
                                                key={'ss'}
                                                onClick={()=>fetchArticlesByCategory('SANTE_SEXUELLE',1)}>
                                                Sante Sexuel
                                            </Tag>
                                            <Tag
                                                title={"Planning Familiale"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 2 ? "green" : "blue"}
                                                key={'pf'}
                                                onClick={()=>fetchArticlesByCategory('PLANNING_FAMILLIAL',2)}>
                                                Planning Familiale
                                            </Tag>
                                            <Tag
                                                title={"Hygiene Mentruel"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 3 ? "green" : "blue"}
                                                key={'hm'}
                                                onClick={()=>fetchArticlesByCategory('HYGENE_MENSTRUELLE',3)}>
                                                Hygiene Mentruel
                                            </Tag>
                                            <Tag
                                                title={"Grossesse Precosse"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 4 ? "green" : "blue"}
                                                key={'gp'}
                                                onClick={()=>fetchArticlesByCategory('GROSSESSE_PRECOSE',4)}>
                                                Grossesse Precosse
                                            </Tag>
                                            <Tag
                                                title={"VIH & IST"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 5 ? "green" : "blue"}
                                                key={'istvih'}
                                                onClick={()=>fetchArticlesByCategory('VIH_IST',5)}>
                                                VIH & IST
                                            </Tag>
                                            <Tag
                                                title={"Drogue et Stupefiant"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 6 ? "green" : "blue"}
                                                key={'drogue'}
                                                onClick={()=>fetchArticlesByCategory('DROGUE_STUPEFIANT',6)}>
                                                Drogue et Stupefiant
                                            </Tag>
                                            <Tag
                                                title={"IVG"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 7 ? "green" : "blue"}
                                                key={'ivg'}
                                                onClick={()=>fetchArticlesByCategory('IVG',7)}>
                                                IVG
                                            </Tag>
                                            <Tag
                                                title={"VBG"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 8 ? "green" : "blue"}
                                                key={'vbg'}
                                                onClick={()=>fetchArticlesByCategory('VVG',8)}>
                                                Violence Base sur le Genre
                                            </Tag>
                                        </div>
                                        <TableTemplate
                                            columns={columns}
                                            loading={loading}
                                            addBTN={<AddArticle loading={"right"} action={"Ajouter"} />}
                                            datas={articles} />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}
