import React, {useEffect, useState} from 'react';
import {Button, Form, Input, message, Modal, Select, Space, Spin} from 'antd';
import { useSelector } from "react-redux";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {PlusCircleFilled} from "@ant-design/icons";

export default function AddGroup() {
    const {accessToken} = useSelector(state => state.authUser);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [roles, setRoles] = useState([]);
    const option = [];
    const openModal = () => {
        form.resetFields();
        setModalAddOpen(true)
    }
    const fetchRoles = () => {
        axiosInstance(accessToken).get('api/groupes/roles')
            .then(
                response => {
                    setRoles(response.data);
                }
            );

    };
    useEffect(() => {
        fetchRoles();
    }, []);
    const onSubmit = (values) => {
        setLoading(true);

        const dat = values;
        let id = [];
        let rolesValue = [];
        id = values.roles;
        for (let g in roles) {
            if (id.includes(roles[g].id))
                rolesValue.push(roles[g]);
        }
        dat.roles = rolesValue;
        setLoading(false);
        axiosInstance(accessToken).post(
            "api/groupes",
            dat,
        ).then(
            (result) => {
                message.success('Groupe ' + dat.libelle + ' Create');
                form.resetFields();
                setLoading(false);
                window.location.reload();
                setModalAddOpen(false);
            }).catch((result) => {
            message.error(result.response.data.message);
            setLoading(false);
        });
    };

    return(
        <>
            <Button style={{float: "right"}}  onClick={openModal} >
                <i aria-hidden="true"><PlusCircleFilled /></i>Ajouter
            </Button>
            <Modal
                title="Creation d'un Groupes"
                style={{
                    top: 20,
                }}
                width={1000}
                open={modalAddOpen}
                maskClosable={false}
                closable={false}
                footer={[]}
            >
                <Spin spinning={loading}>
                    <Form onFinish={onSubmit} form={form}>
                        <Form.Item
                            label="Nom"
                            name="name"
                            rules={
                                [
                                    { required: true, message: 'Veuillez entrer le nom' },
                                    {type:"string", message:"Entrer un nom valide !"}
                                ]}
                            hasFeedback>
                            <Input placeholder="entrer le nom"/>
                        </Form.Item>
                        <Form.Item
                            label="Libelle"
                            name="libelle"
                            rules={
                                [
                                    { required: true, message: 'Veuillez entrer le libelle du groupe' },
                                    {type:"string", message:"Entrer un libelle valide !"}
                                ]}
                            hasFeedback>
                            <Input placeholder="entrer le Libelle"/>
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={
                                [
                                    { required: true, message: 'Veuillez entrer la Date de Naissance/Creation' },
                                    {type:"string", message:"Entrer une description "}
                                ]}
                            hasFeedback>
                            <Input.TextArea rows={4} placeholder="Description" ></Input.TextArea>
                        </Form.Item>

                        <Form.Item
                            label="Roles"
                            name="roles">
                            <Select mode="multiple" placeholder="Choisir les roles pour ce groupe">
                                {
                                    roles.map((role)=>{
                                        return (<Select.Option
                                            key={role.id}
                                            value={role.id}>{role.nom}
                                        </Select.Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Space style={{float:'right'}}>
                                <Button type='primary' htmlType='submit' disabled={loading}>
                                    Submit
                                </Button>
                                <Button danger  disabled={loading}  onClick={() => setModalAddOpen(false)} >
                                    <i className="fa fa-times" aria-hidden="true"></i> cancel
                                </Button>

                            </Space>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    );

}