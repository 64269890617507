import React from 'react';
import {Divider, Table} from "antd";

export default function TableTemplate ({addBTN, columns, datas, loading}) {

    return (
        <>
            {addBTN}
            <Divider />
            <Table
                columns={columns.map((column) => ({
                    ...column,
                    key: column.dataIndex, // Utilisez une propriété unique comme clé pour les colonnes
                }))}
                dataSource={datas.map((data) => ({
                    ...data,
                    key: data.id, // Utilisez une propriété unique comme clé pour les lignes (articles)
                }))}
                loading={loading}
                pagination={{
                    pageSize: 5,
                }} />
        </>
    );
}