import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {Button, Space, Tag, Tooltip} from "antd";
import {EditFilled, InfoCircleFilled} from "@ant-design/icons";
import {ResetPWD} from "../../../components/resetpwd";
import {Active} from "../../../components/active";
import {Sms} from "../../../components/sms";
import BreadCom from "../../../components/breadCom/BreadCom";
import TableTemplate from "../../../components/table";
import AddUser from "../add/AddUser";

export default function ListUser() {
    const {accessToken, droits} = useSelector(state => state.authUser);
    const [current, setCurrent] = useState(0);
    const [loading, setLoading] = useState(true);
    const nav = useNavigate();
    const [users, setUsers] = useState([]);
    const [group, setGroup] = useState([]);
    const items = [];

    useRole(['ROOT','SUPERADMIN']);
    useTitle('Liste des Utilisateurs');
    const navigate= (link)=>{
        nav(link);
    }
    const fetchGroupes = () => {
        axiosInstance(accessToken).get('api/groupes').then(
            (response)=>{
                setGroup(response.data);
                group.forEach(
                    data=>{
                        items.push(
                            {
                                label: data.libelle,
                                key: data.id,
                            }
                        )
                    }
                );
            }
        ).catch( (error)=>{
        });
    }
    const getData = ()=>{
        setLoading(true);
        axiosInstance(accessToken).get('user/list/'+current).then(
            (responses)=>{
                setUsers(responses.data);
                setLoading(false);
            }).catch(
            (error)=>{
                setLoading(false);
            }
        );
    }
    const fetchUsers = (idGroup) => {
        if(idGroup ==null) {
            setCurrent(0);
        }else {
            setCurrent(idGroup.id)
        }
        getData();
    };
    useEffect(() => {
        fetchGroupes();
        fetchUsers(null);
    }, []);
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            key: 'name',
            render:  (_, record) => (
                <>
          <span>
            {record.nom} {record.prenom}
          </span>
                </>
            ),
        },
        {
            title: 'N°Tel',
            dataIndex: 'numero_telephone',
            key: 'numero_telephone',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Groupes',
            key: 'groupe',
            render:  (_, record) => (
                <>
          <span>
            {record.groupe.name} ({record.groupe.libelle})
          </span>
                </>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            render:  (_, record) => {
                let result = null;
                if(record.status === 'ACTIVE'){
                    result = (
                        <Tag color="green" key={record.id} >
                            Activer
                        </Tag>
                    )
                }else if(record.status === 'INACTIVE'){
                    result = (
                        <Tag color="volcano"  key={record.id}>
                            Desactiver
                        </Tag>
                    )
                }else {
                    result = (
                        <Tag color="volcano"  key={record.id}>
                            Unknow
                        </Tag>
                    )
                }
                return (
                    <>
                        {result}
                    </>
                )
            } ,
        },
        {
            title: 'Action',
            key: 'action',
            render:  (_, record) => {
                return  (
                    <>
                        <Space>
                            <Tooltip title={"Detail sur l'utilisateur"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/user/'+record.id)} >
                                    <i aria-hidden="true"><InfoCircleFilled /></i>
                                </Button>
                            </Tooltip>
                            <Tooltip title={"Mise a jour de l'utilisateur"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/user/update/'+record.id)} >
                                    <i aria-hidden="true"><EditFilled /></i>
                                </Button>
                            </Tooltip>
                            <ResetPWD status={record.status} path={"user/reset-password/"} id={record.id}/>
                            <Active status={record.status} path={"user/active/"} id={record.id}/>
                            <Sms message="SMS Individuel" listNum={[record]} />
                        </Space>
                    </>
                )
            }
        },
    ];

    return(
        <>
            <BreadCom name={"Liste des Utilisateur"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Liste des Utilisateurs
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <div>
                                            <strong>Recherche par groupe :</strong>
                                            <br/>
                                            <br/>
                                            {
                                                group.map((groupe)=>{
                                                    if(droits.includes('SUPERADMIN')){
                                                        return (
                                                            <Tag title={groupe.name} style={{cursor: 'pointer'}}  color={current === groupe.id ? "green" : "blue"}  key={groupe.id} onClick={()=>fetchUsers(groupe)}>
                                                                {groupe.name}
                                                            </Tag>
                                                        )
                                                    }else if(!(droits.includes('SUPERADMIN') && groupe.name === 'SUPERADMIN')) {
                                                        return (
                                                            <Tag
                                                                title={groupe.name}
                                                                style={{ cursor: 'pointer' }}
                                                                color={current === groupe.id ? 'green' : 'blue'}
                                                                key={groupe.id}
                                                                onClick={() => fetchUsers(groupe)}
                                                            >
                                                                {groupe.name}
                                                            </Tag>
                                                        );
                                                    }
                                                    return null;

                                                })
                                            }
                                            <br />
                                            <Sms message="SMS de Groupe"  listNum={users} />
                                        </div>
                                        <TableTemplate columns={columns} loading={loading} addBTN={<AddUser />} datas={users} />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );

}