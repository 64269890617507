import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal, Select, Space, message, Spin} from 'antd';
import { useSelector } from "react-redux";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";

export default function AddCP() {
    const {accessToken} = useSelector(state => state.authUser);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const validateAge = (_, date) => {
        if (date && date.isAfter()) {
            return Promise.reject('La date de naissance ne peut pas être dans le futur.');
        }

        const age = date ? new Date().getFullYear() - date.year() : 0;
        if (age < 15) {
            return Promise.reject('L\'âge doit être supérieur ou égal à 15 ans.');
        }

        return Promise.resolve();
    };
    const dateFormat = 'DD-MM-YYYY';
    const [form] = Form.useForm();
    const [group, setGroup] = useState([]);
    const openModal =()=>{
        form.resetFields();
        setLoading(false);
        setModalAddOpen(true)
    }
    const fetchGroupes = () => {
            axiosInstance(accessToken).get('user')
                .then(
                    response=>{
                        setGroup(response.data);
                    }
                ).catch(
                    error=>{
                        message.error("User not found")
                    }
            );

    };
    useEffect(() => {
        fetchGroupes();
    }, []);
    const onSubmit = (values) => {
        setLoading(true);
        const dat =values;
        let id = 0;
        id=values.createur;
        for (let g in group){
            if(group[g].id === id)
                dat.createur = group[g];
        }
        dat.status = 'ACTIVE';
        dat.photo = "photo";
        axiosInstance(accessToken).post(
            "api/centrepartenaire/save",
            dat,
        ).then(
            (result)=> {
                message.success('Centre partenaire '+dat.libelle+' '+dat.nom+' Create');


                setTimeout(
                    ()=>{
                        form.resetFields();
                        setLoading(false);
                        window.location.reload();
                        setModalAddOpen(false);
                    },5000
                )
            }).catch((result)=> {
            message.error('Error');
            setLoading(false);
        });
    }
    return(
        <>
            <Button style={{float: "right"}}  onClick={openModal} >
                <i className="fa fa-plus" aria-hidden="true"></i>Ajouter
            </Button>
            <Modal
                title="Creation d'un Centre Partenaire"
                style={{
                    top: 20,
                }}
                width={1000}
                open={modalAddOpen}
                maskClosable={false}
                closable={false}
                footer={[]}
            >
                <Spin spinning={loading}>
                    <Form onFinish={onSubmit} form={form}>
                        <Form.Item
                            label="Nom"
                            name="nom"
                            rules={
                                [
                                    { required: true, message: 'Veuillez entrer le nom' },
                                    {type:"string", message:"Entrer un nom valide !"}
                                ]}
                            hasFeedback>
                            <Input placeholder="entrer le nom"/>
                        </Form.Item>
                        <Form.Item
                            label="Abbreviation"
                            name="libelle"
                            rules={
                                [
                                    { required: true, message: "Veuillez entrer l'\abbreviation" },
                                    {type:"string", message:"Veuillez entrer l'\abbreviation"}
                                ]}
                            hasFeedback>
                            <Input placeholder="Veuillez entrer l'\abbreviation"/>
                        </Form.Item>

                        <Form.Item
                            label="Latitude"
                            name="latittude"
                            rules={
                                [
                                    { required: true, message: "Veuillez entrer la Latitude" },
                                    {type:"string", message:"Veuillez entrer des chiffre"}
                                ]}
                            hasFeedback>
                            <Input placeholder="Veuillez entrer la Latitude"/>
                        </Form.Item>
                        <Form.Item
                            label="Longitude"
                            name="longitude"
                            rules={
                                [
                                    { required: true, message: "Veuillez entrer la Longitude" },
                                    {type:"string", message:"Veuillez entrer la Longitude"}
                                ]}
                            hasFeedback>
                            <Input placeholder="Veuillez entrer la Longitude"/>
                        </Form.Item>
                        <Form.Item
                            label="Administrateur"
                            name="createur">
                            <Select placeholder="Choisir l'administrateur">
                                {
                                    group.map((groupe)=>{
                                        return (<Select.Option
                                            key={groupe.id}
                                            value={groupe.id}>{groupe.nom} {groupe.prenom}  ({groupe.groupe.name})
                                        </Select.Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="N°Tel"
                            name="telephone"
                            rules={[
                                { required: true, message: 'Veuillez entrer le n°tel' },
                                {type:"string", message:"Entrer le numéro de téléphone!"},
                                {len:9,message:"Veillez saisir un numéro de téléphone valide !"}
                            ]}
                            hasFeedback>
                            <Input placeholder="entrer n°Tel"/>
                        </Form.Item>
                        <Form.Item
                            label="Adresse"
                            name="location"
                            hasFeedback>
                            <Input placeholder="Saisir votre adresse" />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            hasFeedback>
                            <Input.TextArea row={4} placeholder="entrer la description"></Input.TextArea>
                        </Form.Item>
                        <Form.Item>
                            <Space style={{float:'right'}}>
                                <Button type='primary' htmlType='submit' disabled={loading}>
                                    Submit
                                </Button>
                                <Button danger  disabled={loading}  onClick={() => setModalAddOpen(false)} >
                                    <i className="fa fa-times" aria-hidden="true"></i> cancel
                                </Button>

                            </Space>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}