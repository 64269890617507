import {Button, Tooltip, message, Form, Spin, Input, Space, Modal, Tag, notification} from "antd";
import React, {useState} from "react";
import { useSelector } from "react-redux";

export function Sms({listNum,  message}) {
    const {accessToken} = useSelector(state => state.authUser);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const openModal =()=>{
        if(listNum.length > 0) {
            form.resetFields();
            setModalAddOpen(true)
        }else {
            notification.warning({
                style: {
                    zIndex: 0
                },
                message: 'SMS',
                description:
                    'Choisir un ou plusieurs utilisateurs',

            })
        }
    }

    const onChange =async ()=>{
        setLoading(true);
        notification.info({
            style: {
                zIndex: 0
            },
            message: 'SMS',
            description:
                'SMS encours d\'envois',

        })
        setModalAddOpen(false);
        setLoading(false);

        /*setLoading(true);
        await axiosInstance(accessToken).put(
            "/send-sms"
        ).then((result)=> {
            message.success("Done ...")
            setLoading(false);
        }).catch((result)=>{
            //message.error(result.response.data.message)
            setLoading(false);
        });*/
    }
    return (
        <>
            <Tooltip title={"Send SMS"}>
                <Button color='green' style={{float: "right", marginLeft: "5px"}}  onClick={openModal} >
                    {message}
                </Button>
            </Tooltip>
            <Modal
                title="Send Message"
                style={{
                    top: 20,
                }}
                width={1000}
                open={modalAddOpen}
                maskClosable={false}
                closable={false}
                footer={[]}
            >
                <Spin spinning={loading}>
                    <Form onFinish={onChange} form={form}>
                        <Form.Item
                            label="Message"
                            name="message"
                            hasFeedback>
                            <Input.TextArea row={4} size={255} placeholder="Saisir votre message" />
                        </Form.Item>
                        <Form.Item>
                            <Space style={{float:'right'}}>
                                <Button type='primary' htmlType='submit' disabled={loading}>
                                    Submit
                                </Button>
                                <Button danger  disabled={loading}  onClick={() => setModalAddOpen(false)} >
                                    cancel
                                </Button>

                            </Space>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
}