import {useSelector} from "react-redux";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {Button, Col, Empty, Form, Input, message, Row, Select, Space, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import BreadCom from "../../../components/breadCom/BreadCom";
import {ELEARNING} from "../../../components/utils/Constantes";

export default function UpdateFormation() {
    const {accessToken} = useSelector(state => state.authUser);
    const params = useParams();
    const nav = useNavigate();
    const [form] = Form.useForm();
    const [formations, setFormations] = useState(null);
    const [loading, setLoading] = useState(true);
    useRole(['ROOT','SUPERADMIN']);
    useTitle('MAJ de la Formation');
    const navigation = (link)=>{
        setLoading(true);
        nav(link);
        setLoading(false);
    }

    const getFormationById = () => {
        axiosInstance(accessToken).get('test/api/formations/admin/'+parseInt(params.id))
            .then(
                (response)=>{
                    setFormations(response.data);
                    setLoading(false);
                }
            ).catch(
            (error)=>{
                message.error(error.response.data.message);
                setLoading(false);
            }
        )
    };

    useEffect(
        ()=>{
            setLoading(true)
            getFormationById();
        },[]
    );

    const onUpdate = (values) => {
        setLoading(true);
        const dat =values;
        axiosInstance(accessToken).put(
            "test/api/formations/"+formations.id,
            dat,
        ).then(
            (result)=> {
                message.success('Formation '+dat.libelle+' Update');
                form.resetFields();
                navigation(ELEARNING);
                setLoading(false);
            }).catch((result)=> {
            message.error(result.response.data.message);
            setLoading(false);
        });
    }

    const returnValue = formations ==null ? <>
        <Spin spinning={loading}>
            <Empty />
        </Spin>
    </>  : <>
        <Spin  spinning={loading}>

            <div >
                <Form onFinish={onUpdate} form={form} initialValues={formations}>
                    <Form.Item
                        label="Titre"
                        name="libelle"
                        rules={
                            [
                                { required: true, message: 'Veuillez entrer le Titre' },
                                {type:"string", message:"Entrer un titre valide !"}
                            ]}
                        hasFeedback>
                        <Input placeholder="entrer le Titre"/>
                    </Form.Item>
                    <Form.Item
                        label="Categorie"
                        name="categorie"
                        rules={
                            [
                                { required: true, message: 'Veuillez selectionner la categorie' }
                            ]}
                        hasFeedback>
                        <Select placeholder="Choisir la Categorie">
                            <Select.Option value="SANTE_SEXUELLE">SANTE SEXUELLE</Select.Option>
                            <Select.Option value="IVG">INTERRUPTION VOLONTAIRE GROSSESE</Select.Option>
                            <Select.Option value="PLANNING_FAMILLIAL">PLANNING FAMILLIAL</Select.Option>
                            <Select.Option value="VIH_IST">VIH/IST</Select.Option>
                            <Select.Option value="HYGENE_MENSTRUELLE">HYGENE MENSTRUELLE</Select.Option>
                            <Select.Option value="VVG">VIOLENCE BASE SUR LE GENRE</Select.Option>
                            <Select.Option value="GROSSESSE_PRECOSE">GROSSESSE PRECOSE</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Auteur"
                        name="author"
                        rules={
                            [
                                { required: true, message: 'Veuillez Saisir le nom de l\'auteur' },
                                {type:"string", message:"Entrer un nom valide !"}
                            ]}
                        hasFeedback>
                        <Input placeholder="entrer le nom de l'auteur"/>
                    </Form.Item>
                    <Form.Item>
                        <Space style={{float:'right'}}>
                            <Button type='primary' htmlType='submit' disabled={loading}>
                                Update
                            </Button>
                            <Button danger  disabled={loading}  onClick={() => navigation(ELEARNING)} >
                                <i className="fa fa-times" aria-hidden="true"></i> cancel
                            </Button>

                        </Space>
                    </Form.Item>
                </Form>
                <div>

                    <Button onClick={()=>{
                        navigation(ELEARNING+'/update/'+formations.id+'/chatpter')
                    }}> Liste des Chapitres</Button>
                    <hr />
                    <Outlet />
                </div>


            </div>
        </Spin>
    </>

    return (
        <>
            <BreadCom name={"Formation update"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Update
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0" style={
                                        {
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }
                                    }>
                                        {returnValue}
                                    </div>
                                    <div className="tab-content p-0" style={
                                        {
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }
                                    }>

                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );

}