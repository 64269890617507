import {Link, Outlet, useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Empty, Form, message, Row, Spin} from "antd";
import {useRole, useTitle} from "../../../components/hook/index";
import {useSelector} from "react-redux";
import BreadCom from "../../../components/breadCom/BreadCom";

export default function UpdateGroup() {
    const {accessToken} = useSelector(state => state.authUser);
    const params = useParams();
    const nav = useNavigate();
    const [form] = Form.useForm();
    const [groupUpdate, setGroupUpdate] = useState(null);
    const [loading, setLoading] = useState(true);

    useRole(['ROOT','SUPERADMIN']);
    useTitle('MAJ du Groupe');

    let initialValue = {};
    const navigation = (link)=>{
        setLoading(true);
        nav(link);
        setLoading(false);
    }
    const onUpdate = (values) => {
        setLoading(true);
        const dat =values;
        axiosInstance(accessToken).put(
            "api/groupes/update/"+groupUpdate.id,
            dat,
        ).then(
            (result)=> {
                message.success('Group '+dat.name+' Update');
                form.resetFields();
                navigation('/admin/group');
                setLoading(false);
            }).catch((result)=> {
            message.error(result.response.data.message);
            setLoading(false);
        });
    }
    const getGroupeById = () => {
        axiosInstance(accessToken).get('api/groupes/getById/'+parseInt(params.id))
            .then(
                (response)=>{
                    setGroupUpdate(response.data);
                    setLoading(false);

                }
            ).catch(
            (error)=>{
                message.error(error.response.data.message);
                setLoading(false);
            }
        )
    }

    useEffect(
        ()=>{
            setLoading(true)
            getGroupeById();
        },[]
    )
    const returnValue = groupUpdate ==null ? <>
        <Spin spinning={loading}>
            <Empty />
        </Spin>
    </>  : <>
        <Spin  spinning={loading}>
            <div >
                <Row style={{marginTop: '15px'}}>
                <Col >
                    <Button onClick={()=>{
                        navigation('/admin/group/update/'+groupUpdate.id+'/role')
                    }}> Liste des droits</Button>

                </Col>
               {/* <Col >
                    <Button onClick={()=>{
                        navigation('/admin/group/update/'+groupUpdate.id+'/user')
                    }}> Liste des Utilisateurs</Button>
                </Col>*/}
            </Row>

                <div>
                    <Outlet />
                </div>


            </div>
        </Spin>
    </>

    return (
        <>
            <BreadCom name={"Groupe update"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Update
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0" style={
                                        {
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }
                                    }>
                                        {returnValue}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}