import {useSelector} from "react-redux";
import React, {useState} from "react";
import {Button, Form, Input, message, Modal, Select, Space, Spin} from "antd";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";

export default function AddFormation() {
    const {accessToken} = useSelector(state => state.authUser);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const openModal =()=>{
        form.resetFields();
        setLoading(false)
        setModalAddOpen(true)
    }
    const onSubmit = (values) => {
        setLoading(true);
        const dat={
            id: 0,
            libelle: values.libelle,
            categorie: values.categorie,
            chapitres: null,
            comments: null,
            status: true,
            description: values.description,
            author: values.author
        };
        axiosInstance(accessToken).post(
            "test/api/formations",
            dat,
        ).then(
            (result)=> {
                message.success('Formation '+dat.libelle+' Create');
                form.resetFields();
                setLoading(false);
                setTimeout(
                    ()=>{
                        window.location.reload();
                        setModalAddOpen(false);
                    },2000
                )
            }).catch((result)=> {
            message.error(result.response.data.message);
            setLoading(false);
        });
    }
    return(
        <>
            <Button style={{float: "right"}}  onClick={openModal} >
                <i className="fa fa-plus" aria-hidden="true"></i>Ajouter
            </Button>
            <Modal
                title="Creation d'une Formation"
                style={{
                    top: 20,
                }}
                width={1000}
                open={modalAddOpen}
                maskClosable={false}
                closable={false}
                footer={[]}
            >
                <Spin spinning={loading}>
                    <Form onFinish={onSubmit} form={form}>
                        <Form.Item
                            label="Titre"
                            name="libelle"
                            rules={
                                [
                                    { required: true, message: 'Veuillez entrer le Titre' },
                                    {type:"string", message:"Entrer un titre valide !"}
                                ]}
                            hasFeedback>
                            <Input placeholder="entrer le Titre"/>
                        </Form.Item>
                        <Form.Item
                            label="Categorie"
                            name="categorie"
                            rules={
                                [
                                    { required: true, message: 'Veuillez selectionner la categorie' }
                                ]}
                            hasFeedback>
                            <Select placeholder="Choisir la Categorie">
                                <Select.Option value="SANTE_SEXUELLE">SANTE SEXUELLE</Select.Option>
                                <Select.Option value="IVG">INTERRUPTION VOLONTAIRE GROSSESE</Select.Option>
                                <Select.Option value="PLANNING_FAMILLIAL">PLANNING FAMILLIAL</Select.Option>
                                <Select.Option value="VIH_IST">VIH/IST</Select.Option>
                                <Select.Option value="HYGENE_MENSTRUELLE">HYGENE MENSTRUELLE</Select.Option>
                                <Select.Option value="VVG">VIOLENCE BASE SUR LE GENRE</Select.Option>
                                <Select.Option value="GROSSESSE_PRECOSE">GROSSESSE PRECOSE</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Auteur"
                            name="author"
                            rules={
                                [
                                    { required: true, message: 'Veuillez Saisir le nom de l\'auteur' },
                                    {type:"string", message:"Entrer un nom valide !"}
                                ]}
                            hasFeedback>
                            <Input placeholder="entrer le nom de l'auteur"/>
                        </Form.Item>
                        <Form.Item
                            label="Resume"
                            name="description"
                            rules={
                                [
                                    { required: true, message: 'Veuillez entrer un bref resume' },
                                    {type:"string", message:"Saisir un resume !"}
                                ]}
                            hasFeedback>
                            <Input.TextArea bordered={true}></Input.TextArea>
                        </Form.Item>
                        <Form.Item>
                            <Space style={{float:'right'}}>
                                <Button type='primary' htmlType='submit' disabled={loading}>
                                    Submit
                                </Button>
                                <Button danger  disabled={loading}  onClick={() => setModalAddOpen(false)} >
                                    <i className="fa fa-times" aria-hidden="true"></i> cancel
                                </Button>

                            </Space>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}