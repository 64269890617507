import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode"; // Correction de l'importation
import axiosInstance from "../axiosInstance/AxiosInstance";
import {baseUrl} from "../baseUrl/BaseUrl";
import {toastClick} from "../../components/toast/CustomToast";
import {DASHBOARD} from "../../components/utils/Constantes";

function isValidJSON(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
/*

const authSliceUser = createSlice({
    name: 'authUser',
    initialState: {
        accessToken: sessionStorage.getItem('accessTokenU'),
        refreshToken: sessionStorage.getItem('refreshTokenU'),
        tokensubject: sessionStorage.getItem('subU'),
        droits: isValidJSON(sessionStorage.getItem('rolesU')) ? JSON.parse(sessionStorage.getItem('rolesU')) : [],
        user: isValidJSON(sessionStorage.getItem('userU')) ? JSON.parse(sessionStorage.getItem('userU')) : null,
        isAuth: sessionStorage.getItem('isAuthU'),
    },

    reducers: {
        setAccessToken: (state, action) => {
            state.accessToken = sessionStorage.getItem('accessTokenU')
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = sessionStorage.getItem('refreshTokenU')
        },
        setSubject: (state, action) => {
            state.tokensubject = sessionStorage.getItem('subU')
        },
        setDroits: (state, action) => {
            state.droits = isValidJSON(sessionStorage.getItem('rolesU')) ? JSON.parse(sessionStorage.getItem('rolesU')) : [];
        },
        setUser: (state, action) => {
            state.user = isValidJSON(sessionStorage.getItem('userU')) ? JSON.parse(sessionStorage.getItem('userU')) : null;
            state.isAuth = state.user ? state.user.isFirstConnexion : false;
        },
        logOut: (state, action) => {
            sessionStorage.clear()
            state.droits = [];
            state.tokensubject = null;
            state.accessToken = null;
            state.user = null;
        },
        getToken: (state) => {
            return state.accessToken;
        }
    }
})


*/

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        accessToken: localStorage.getItem('accessTokenAdmin'),
        refreshToken: localStorage.getItem('refreshTokenAdmin'),
        tokensubject: localStorage.getItem('subAdmin'),
        droits: localStorage.getItem('rolesAdmin') == undefined ? [] : JSON.parse(localStorage.getItem('rolesAdmin')),
        user:JSON.parse(localStorage.getItem('userAdmin')),
        isAuth: localStorage.getItem('isAuthAdmin'),
    },
    reducers: {
        setAccessToken: (state, action) => {
            state.accessToken = localStorage.getItem('accessTokenAdmin')
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = localStorage.getItem('refreshTokenAdmin')
        },
        setSubject: (state, action) => {
            state.tokensubject = localStorage.getItem('subAdmin')
        },
        setDroits: (state, action) => {
            state.droits = JSON.parse(localStorage.getItem('rolesAdmin'));
        },
        setUser: (state, action) => {
            state.user = JSON.parse(localStorage.getItem('userAdmin'));
            state.isAuth = state.user.isFirstConnexion;
        },
        logOut: (state, action) => {
            localStorage.clear();
            state.droits = [];
            state.tokensubject = null;
            state.accessToken = null;
            state.user = null;
        },
        getToken: (state) => {
            return state.accessToken;
        }
    }
})

export const { setRefreshToken, setAccessToken, setDroits, logOut,setSubject, setUser, getToken } = authSlice.actions



export const loginUser = (identifier, password) => async dispatch => {
    console.log("identifier = ",identifier,", password = "+password)
    console.log("baseUrl = ",baseUrl)
    await axiosInstance().post(
        baseUrl+"api/auth/login",
        {
            login : identifier,
            password: password
        }
    ).then(response=>{
        let accessToken = response.data.accessToken.replace(/^Bearer\s/, '');
        const refreshToken = response.data.refreshToken;
        let decodedToken;
        try {
            decodedToken = jwt_decode(accessToken);
            // Vérifier si le token est expiré
            const currentTime = Math.floor(Date.now() / 1000);
            if (decodedToken.exp < currentTime) {
                toastClick("Token expiré, veuillez vous reconnecter", "error");
                return;
            }
        } catch (error) {
            toastClick("Erreur lors du décodage du token", "error");
            return;
        }
        localStorage.setItem('accessTokenAdmin',accessToken);
        localStorage.setItem('refreshTokenAdmin',refreshToken);
        localStorage.setItem('rolesAdmin',JSON.stringify(decodedToken.roles));
        localStorage.setItem('subAdmin',decodedToken.sub);
        localStorage.setItem('userAdmin',JSON.stringify(response.data.user));
        localStorage.setItem('isAuthAdmin',response.data.user.isFirstConnexion);
        if(response.data.user.isFirstConnexion)
            sessionStorage.setItem('password',"123456789")
        dispatch(setAccessToken(localStorage.getItem('accessTokenAdmin')));
        dispatch(setRefreshToken(localStorage.getItem('refreshTokenAdmin')));
        dispatch(setDroits(localStorage.getItem('rolesAdmin')));
        dispatch(setSubject(localStorage.getItem('subAdmin')));
        dispatch(setUser(response.data.user));
        toastClick("Welcome", "success");
        window.location.href=DASHBOARD;
    }).catch(error => {
        toastClick("Login / Ou Mot de passe Invalide", "error");
    });

}


export const updateIsFirstConnexion = () => dispatch => {
    let user = JSON.parse(localStorage.getItem('userAdmin'));
    user.isFirstConnexion = false;
    localStorage.setItem('userAdmin',JSON.stringify(user));
}

export const logOutUser = () => async dispatch => {
    dispatch(logOut(""));
}

export default authSlice.reducer;
