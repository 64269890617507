import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Empty, Spin} from "antd";
import {useSelector} from "react-redux";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {toastClick} from "../../../components/toast/CustomToast";
import BreadCom from "../../../components/breadCom/BreadCom";

export default function ViewDenonciation() {
    const {accessToken} = useSelector(state => state.authUser);
    const params = useParams();
    const nav = useNavigate();
    const [denonciation, setDenonciation] = useState(null);
    const [loading, setLoading] = useState(true);
    useRole(['ROOT', 'SUPERADMIN']);
    useTitle('View Denonciation');
    const navigation = (link) => {
        setLoading(true);
        nav(link);
        setLoading(false);
    }
    const getDenonciationById = () => {
        axiosInstance(accessToken).get('denonciation/find-by-id/' + parseInt(params.id))
            .then(
                (response) => {
                    setDenonciation(response.data);
                    setLoading(false);
                }
            ).catch(
            (error) => {
                toastClick(error.response.data.message,'error');
                setLoading(false);
            }
        )
    }

    useEffect(
        () => {
            setLoading(true)
            getDenonciationById();
        }, []
    )
    const returnValue = denonciation == null ? <>
        <Spin spinning={loading}>
            <Empty/>
        </Spin>
    </> : <>
        <Spin spinning={loading}>
            <div>
                <label>
                    <strong>Auteur :</strong><span style={{
                    fontSize: '24px',
                    padding: "5px",
                    marginTop: '5px'
                }}>{denonciation.utilisateur == null ? "Anonymous" : denonciation.utilisateur.nom}</span>
                </label>
                <hr />
                <label>
                    <strong>Date :</strong><span style={{
                    fontSize: '24px',
                    padding: "5px",
                    marginTop: '5px'
                }}>{denonciation.dateDenonciation}</span>
                </label>
                <hr />
                <label>
                    <strong>Contenu :</strong><span style={{
                    fontSize: '24px',
                    padding: "5px",
                    marginTop: '5px'
                }}>{denonciation.contenu == null ? "empty" : denonciation.contenu}</span>
                </label>
            </div>
        </Spin>
    </>;

    return(
        <>
            <BreadCom name={"Denonciation info"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Detail
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        {returnValue}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );

}