import {useSelector} from "react-redux";
import {useState} from "react";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {toastClick} from "../../../components/toast/CustomToast";
import {Button, Form, Input, Modal, Select, Space} from "antd";
import {PlusCircleFilled} from "@ant-design/icons";

export default function AddParametre({action, style}) {
    const {accessToken} = useSelector(state => state.authUser);

    const [selectedValue, setSelectedValue] = useState(null);
    const handleSelectChange = (value) => {
        setSelectedValue(value);
    };
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const openModal =()=>{
        setLoading(false)
        setModalAddOpen(true)
    }
    const onClose = () => {
        setLoading(true)
        setModalAddOpen(false);

    }
    const onSubmit = async (values) => {
        setLoading(true);
        const dat ={
            "id":null,
            "titre":values.titre,
            "valeur":values.valeur,
            "taille":values.taille,
            "paramTypeEnum":selectedValue,
            "status":false,
        }
        axiosInstance(accessToken).post(
            "api/parametres",
            dat,
        ).then((result)=> {
            toastClick("Done ...",'success');
            setModalAddOpen(false);
            window.location.reload();
            setLoading(false);
        }).catch((result)=>{
            setLoading(false);
            toastClick(result.response.data.message,'error')
        });
    }

    return(
        <>
            <Button style={{float: "right"}}  onClick={openModal} >
                <i aria-hidden="true"><PlusCircleFilled /></i> {action}
            </Button>
            <Modal
                title="Creation d'un Parametre"
                style={{
                    top: 20,
                }}
                width={1000}
                open={modalAddOpen}
                maskClosable={false}
                closable={false}
                footer={[]}
            >
                <Form onFinish={onSubmit}>
                    <Form.Item
                        label="Titre"
                        name="titre"
                        rules={
                            [
                                { required: true, message: 'Veuillez entrer le titre' },
                                {type:"string", message:""}
                            ]}
                        hasFeedback>
                        <Input />
                    </Form.Item>
                    <Form.Item

                        label="Valeur"
                        name="valeur"
                        rules={
                            [
                                { required: true, message: "Veuillez entrer la valeur" },
                                {type:"string", message:"Entrer un chiffre !"}
                            ]}
                        hasFeedback>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Taille"
                        name="taille"
                        rules={
                            [
                                { required: true, message: "Veuillez entrer la taille" },
                                {type:"string", message:"Entrer une taille !"}
                            ]}
                        hasFeedback>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Type"
                        name="paramTypeEnum">
                        <Select  value={selectedValue} onChange={handleSelectChange} placeholder="Choisir le Type de Parametre">
                            <Select.Option value="WHATSAPP">Numero WhatsApp</Select.Option>
                            <Select.Option value="CALL">Numero pour les appels telephonique</Select.Option>
                            <Select.Option value="SMS">Numero pour les sms</Select.Option>
                            <Select.Option value="STATISTICS">Statistiques medicale</Select.Option>
                            <Select.Option value="FACEBOOK">Lien Facebook</Select.Option>
                            <Select.Option value="TWEETER">Lien X (Tweeter) de l'application</Select.Option>
                            <Select.Option value="YOUTUBE">Lien YouTube de l'application</Select.Option>
                            <Select.Option value="LOCALISATION">Carte de l'acceuil</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Space style={{float:'right'}}>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Submit
                            </Button>
                            <Button danger loading={loading} onClick={onClose} >
                                <i className="fa fa-times" aria-hidden="true"></i> cancel
                            </Button>

                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );


}