import React, {useEffect, useState} from "react";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {Button, Space, Tag, Tooltip} from "antd";
import {useNavigate} from "react-router-dom";
import {Active} from "../../../components/active";
import {useRole, useTitle} from "../../../components/hook";
import {useSelector} from "react-redux";
import {InfoCircleFilled} from "@ant-design/icons";
import BreadCom from "../../../components/breadCom/BreadCom";
import TableTemplate from "../../../components/table";

export default function ListTemoignage(){
    const {accessToken} = useSelector(state => state.authUser);
    const [current, setCurrent] = useState(0);
    const [loading, setLoading] = useState(true);
    const nav = useNavigate();
    const [temoignages, setTemoignages] = useState([]);
    useRole(['ROOT','SUPERADMIN']);
    useTitle('Liste des Temoignages');
    const navigate= (link)=>{
        nav(link);
    }

    const fetchTemoignagesByCategory = (categorie, color)=>{
        setLoading(true);
        axiosInstance(accessToken).get('api/temoignage/get-by-categorie/'+categorie).then(
            (response)=>{
                setCurrent(color);
                setTemoignages(response.data);
                setLoading(false);
            }
        ).catch( (error)=>{
            setLoading(false);
        });
    }
    const fetchTemoignages = () => {
        setLoading(true);
        axiosInstance(accessToken).get('api/temoignage').then(
            (response)=>{
                setCurrent(0);
                setTemoignages(response.data);
                setLoading(false);
            }
        ).catch( (error)=>{
            setLoading(false);
        });
    }
    useEffect(() => {
        fetchTemoignages();
    }, []);
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Titre',
            key: 'title',
            render:  (_, record) => (
                <>
          <span>
            {record.title}
          </span>
                </>
            ),
        },
        {
            title: 'Auteur',
            key: 'author',
            render:  (_, record) => (
                <>
          <span>
            {record.author}
          </span>
                </>
            ),
        },
        {
            title: 'Lue',
            key: 'lue',
            render:  (_, record) =>{
                let data = record.lue > 0  ? (<>
                    <Tag color="green" key={record.id}>
                        Lue {record.lue} Fois
                    </Tag>
                </>) : (
                    <>
                        <Tag color="volcano" key={record.id}>
                            Lue 0 Fois
                        </Tag>
                    </>
                )
                return (
                    <>
                        {data}
                    </>
                );
            }
        },
        {
            title: 'Status',
            key: 'status',
            render:  (_, record) => {
                let result = null;
                if(record.status){
                    result = (
                        <Tag color="green" key={record.id} >
                            Activer
                        </Tag>
                    )
                }else if(!record.status){
                    result = (
                        <Tag color="volcano"  key={record.id}>
                            Desactiver
                        </Tag>
                    )
                }else {
                    result = (
                        <Tag color="volcano"  key={record.id}>
                            Unknow
                        </Tag>
                    )
                }
                return (
                    <>
                        {result}
                    </>
                )
            } ,
        },
        {
            title: 'Action',
            key: 'action',
            render:  (_, record) => {
                return  (
                    <>
                        <Space>
                            <Tooltip title={"Detail sur le temoignage"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/temoignage/'+record.id)} >
                                    <i aria-hidden="true"><InfoCircleFilled /></i>
                                </Button>
                            </Tooltip>
                            <Active status={record.status} path={"api/temoignage/active/"} id={record.id}/>
                        </Space>
                    </>
                )
            }
        },
    ];

    return(
        <>
            <BreadCom name={"Liste des Temoignages"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Liste des Temoignages
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <div style={{margin: '15px'}}>
                                            <Tag
                                                title={"Tous les Temoignage"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 0 ? "green" : "blue"}
                                                key={'all'}
                                                onClick={()=>fetchTemoignages()}>
                                                Tous
                                            </Tag>
                                            <Tag
                                                title={"Sante Sexuel"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 1 ? "green" : "blue"}
                                                key={'ss'}
                                                onClick={()=>fetchTemoignagesByCategory('SANTE_SEXUELLE',1)}>
                                                Sante Sexuel
                                            </Tag>
                                            <Tag
                                                title={"Planning Familiale"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 2 ? "green" : "blue"}
                                                key={'pf'}
                                                onClick={()=>fetchTemoignagesByCategory('PLANNING_FAMILLIAL',2)}>
                                                Planning Familiale
                                            </Tag>
                                            <Tag
                                                title={"Hygiene Mentruel"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 3 ? "green" : "blue"}
                                                key={'hm'}
                                                onClick={()=>fetchTemoignagesByCategory('HYGENE_MENSTRUELLE',3)}>
                                                Hygiene Mentruel
                                            </Tag>
                                            <Tag
                                                title={"Grossesse Precosse"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 4 ? "green" : "blue"}
                                                key={'gp'}
                                                onClick={()=>fetchTemoignagesByCategory('GROSSESSE_PRECOSE',4)}>
                                                Grossesse Precosse
                                            </Tag>
                                            <Tag
                                                title={"VIH & IST"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 5 ? "green" : "blue"}
                                                key={'istvih'}
                                                onClick={()=>fetchTemoignagesByCategory('VIH_IST',5)}>
                                                VIH & IST
                                            </Tag>
                                            <Tag
                                                title={"Drogue et Stupefiant"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 6 ? "green" : "blue"}
                                                key={'drogue'}
                                                onClick={()=>fetchTemoignagesByCategory('DROGUE_STUPEFIANT',6)}>
                                                Drogue et Stupefiant
                                            </Tag>
                                            <Tag
                                                title={"IVG"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 7 ? "green" : "blue"}
                                                key={'ivg'}
                                                onClick={()=>fetchTemoignagesByCategory('IVG',7)}>
                                                IVG
                                            </Tag>
                                            <Tag
                                                title={"VBG"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 8 ? "green" : "blue"}
                                                key={'vbg'}
                                                onClick={()=>fetchTemoignagesByCategory('VVG',8)}>
                                                Violence Base sur le Genre
                                            </Tag>
                                        </div>
                                        <TableTemplate columns={columns} loading={loading} addBTN={null} datas={temoignages} />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );

}