import React, {useState} from 'react';
import {Button, Form, Input, Modal, Space, message, Spin} from 'antd';
import { useSelector } from "react-redux";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";

export default function AddMission() {
    const {accessToken} = useSelector(state => state.authUser);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const openModal =()=>{
        form.resetFields();
        setLoading(false);
        setModalAddOpen(true)
    }
    const onSubmit = (values) => {
        setLoading(true);
        const dat =values;
        axiosInstance(accessToken).post(
            "api/missions",
            dat,
        ).then(
            (result)=> {
                message.success('Mission '+dat.title+' Create');
                form.resetFields();
                setLoading(false);
                setTimeout(
                    ()=>{
                        window.location.reload();
                        setModalAddOpen(false);
                    },2000
                )
            }).catch((result)=> {
            message.error("Error");
            setLoading(false);
        });
    }
    return(
        <>
            <Button style={{float: "right"}}  onClick={openModal} >
                <i className="fa fa-plus" aria-hidden="true"></i>Ajouter
            </Button>
            <Modal
                title="Creation d'un Mission"
                style={{
                    top: 20,
                }}
                width={1000}
                open={modalAddOpen}
                maskClosable={false}
                closable={false}
                footer={[]}
            >
                <Spin spinning={loading}>
                    <Form onFinish={onSubmit} form={form}>
                        <Form.Item
                            label="Titre"
                            name="title"
                            rules={
                                [
                                    { required: true, message: 'Veuillez entrer le Titre' },
                                    {type:"string", message:"Entrer un titre valide !"}
                                ]}
                            hasFeedback>
                            <Input placeholder="entrer le Titre"/>
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={
                                [
                                    { required: true, message: 'Veuillez entrer la description' },
                                    {type:"string", message:"Entrer une description !"}
                                ]}
                            hasFeedback>
                            <Input.TextArea row={4} placeholder="entrer le Prenom"></Input.TextArea>
                        </Form.Item>
                        <Form.Item>
                            <Space style={{float:'right'}}>
                                <Button type='primary' htmlType='submit' disabled={loading}>
                                    Submit
                                </Button>
                                <Button danger  disabled={loading}  onClick={() => setModalAddOpen(false)} >
                                    <i className="fa fa-times" aria-hidden="true"></i> cancel
                                </Button>

                            </Space>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}