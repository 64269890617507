import BreadCom from "../../../components/breadCom/BreadCom";
import {
    EditFilled,
    FileTextOutlined,
    GlobalOutlined,
    HomeOutlined,
    InfoCircleFilled,
    UsergroupAddOutlined
} from "@ant-design/icons";
import {ARTICLE, DENONCIATION, USER} from "../../../components/utils/Constantes";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {toastClick} from "../../../components/toast/CustomToast";
import {Button, Space, Tag, Tooltip} from "antd";
import {Active} from "../../../components/active";
import TableTemplate from "../../../components/table";
import AddParametre from "../add/AddParametre";

export default function ListParametres(){
    const {accessToken} = useSelector(state => state.authUser);
    const [current, setCurrent] = useState(0);
    const nav = useNavigate();
    const openModalEditor = (record) => {
        setModalUpdateOpen(true);
    };
    useRole(['ROOT','SUPERADMIN']);
    useTitle('Liste des Parametres');
    const onSubmit = async (values,record) => {
        setLoading(true);
        const dat ={
            "id":null,
            "titre":values.titre,
            "valeur":values.valeur,
            "taille":values.taille,
            "paramTypeEnum":record.paramTypeEnum,
            "status":record.status,
        }
        axiosInstance(accessToken).put(
            "api/parametres/"+values.id,
            dat,
        ).then((result)=> {
            toastClick("Done ...", 'success');
            setModalUpdateOpen(false);
            window.location.reload();
            setLoading(false);
        }).catch((result)=>{
            toastClick(result.response.data.message,'error');
            setLoading(false);
        });
    }
    const [params, setParams] = useState([]);
    const [modalUpdateOpen, setModalUpdateOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate= (link)=>{
        nav(link);
    }
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'key',
        },
        {
            title: 'Titre',
            dataIndex: 'titre',
            key: 'titre',
        },
        {
            title: 'Valeur',
            dataIndex: 'valeur',
            key: 'valeur',
        },
        {
            title: 'Taille',
            dataIndex: 'taille',
            key: 'taille',
        },
        {
            title: 'Type',
            dataIndex: 'paramTypeEnum',
            key: 'type',
        },
        {
            title: 'Status',
            key: 'status',
            render:  (_, record) =>{
                let data = record.status ? (<>
                    <Tag color="green" key={record.id}>
                        Actif
                    </Tag>
                </>) : (
                    <>
                        <Tag color="volcano" key={record.id}>
                            Inactif
                        </Tag>
                    </>
                )
                return (
                    <>
                        {data}
                    </>
                );
            }
        },
        {
            title: 'Action',
            key: 'action',
            render:  (_, record) => {


                return (
                    <>
                        <Space>
                            <Tooltip title={"Detail sur le Parametre"}>
                                <Button type="default" shape="circle"  onClick={() => navigate('/admin/parametre/'+record.id)} >
                                    <i aria-hidden="true"><InfoCircleFilled /></i>
                                </Button>
                            </Tooltip>
                            <Tooltip title={"Mise a jour d'un Parametre"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/parametre/update/'+record.id)} >
                                    <i aria-hidden="true"><EditFilled /></i>
                                </Button>
                            </Tooltip>
                            <Active status={record.status} path={"api/parametres/active/"} id={record.id}/>
                        </Space>
                    </>
                );
            },
        },
    ];
    const fetchTemoignagesByCategory = (categorie, color)=>{
        setLoading(true);
        axiosInstance(accessToken).get('api/parametres/'+categorie).then(
            (response)=>{
                setCurrent(color);
                setParams(response.data);
                setLoading(false);
            }
        ).catch( (error)=>{
            setLoading(false);
        });
    }
    const fetchArticle = ()=>{
        setLoading(true);
        setCurrent(0);
        axiosInstance(accessToken).get(
            "api/parametres"
        ).then((result)=> {
            setParams(result.data)
            setLoading(false);
        }).catch((result)=> {
            toastClick(result.response.message,'info')
            setLoading(false);
        });
    }
    useEffect(()=>{
        fetchArticle()
    },[]);
    return(
        <>
            <BreadCom name={"Liste des Parametres"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Liste des Parametres
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <div style={{margin: '15px'}}>
                                            <Tag
                                                title={"Tous les Parametres"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 0 ? "green" : "blue"}
                                                key={'all'}
                                                onClick={()=>fetchArticle()}>
                                                Tous
                                            </Tag>
                                            <Tag
                                                title={"Numero WhatsApp"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 1 ? "green" : "blue"}
                                                key={'wa'}
                                                onClick={()=>fetchTemoignagesByCategory('WHATSAPP',1)}>
                                                WhatsApp
                                            </Tag>
                                            <Tag
                                                title={"Numero Appel"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 2 ? "green" : "blue"}
                                                key={'call'}
                                                onClick={()=>fetchTemoignagesByCategory('CALL',2)}>
                                                Appel Telephonique
                                            </Tag>
                                            <Tag
                                                title={"Numero Message"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 3 ? "green" : "blue"}
                                                key={'sms'}
                                                onClick={()=>fetchTemoignagesByCategory('SMS',3)}>
                                                Messagerie Telephonique
                                            </Tag>
                                            <Tag
                                                title={"Statistiques Medicales"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 4 ? "green" : "blue"}
                                                key={'stat'}
                                                onClick={()=>fetchTemoignagesByCategory('STATISTICS',4)}>
                                                Statistiques Medicales
                                            </Tag>
                                            <Tag
                                                title={"Lien FaceBook"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 5 ? "green" : "blue"}
                                                key={'fbk'}
                                                onClick={()=>fetchTemoignagesByCategory('FACEBOOK',5)}>
                                                Lien Facebook
                                            </Tag>
                                            <Tag
                                                title={"Lien Tweeter"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 6 ? "green" : "blue"}
                                                key={'tweeter'}
                                                onClick={()=>fetchTemoignagesByCategory('TWEETER',6)}>
                                                Lien Tweeter
                                            </Tag>
                                            <Tag
                                                title={"Lien Youtube"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 7 ? "green" : "blue"}
                                                key={'ytb'}
                                                onClick={()=>fetchTemoignagesByCategory('YOUTUBE',7)}>
                                                Lien Youtube
                                            </Tag>
                                            <Tag
                                                title={"Statistiques Carte"}
                                                style={{cursor: 'pointer'}}
                                                color={current === 8 ? "green" : "blue"}
                                                key={'carte'}
                                                onClick={()=>fetchTemoignagesByCategory('LOCALISATION',8)}>
                                                Statistiques Carte
                                            </Tag>
                                        </div>
                                        <TableTemplate
                                            columns={columns}
                                            loading={loading}
                                            addBTN={<AddParametre loading={"right"} action={"Ajouter"} />}
                                            datas={params} />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}