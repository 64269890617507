import * as Utils from '../utils/Constantes';
import {
    BuildOutlined,
    DashboardOutlined,
    FileTextOutlined,
    GlobalOutlined,
    GroupOutlined, HeatMapOutlined, QuestionCircleOutlined, ReadOutlined,
    SettingOutlined,
    UserOutlined
} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {THEME} from "../utils/Constantes";
export default function SideBar(){
    const {user} = useSelector(state => state.authUser);
    useEffect(
        ()=>{
            if(user==null){
                window.location.href='/';
            }

        },[]
    );
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4"  style={{zIndex: 1}}>
            <a className="brand-link">
                <img src="/logo512.png" alt="AdminLTE Logo"
                     className="brand-image img-circle elevation-3" style={{opacity:".8"}} />
                <span className="brand-text font-weight-light">YouthFP</span>
            </a>

            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src="/dist/img/avatar.png" className="img-circle elevation-2" alt="User Image" />
                    </div>
                    <div className="info">
                        <a href={Utils.PROFILE} className="d-block">{user===null ? '' : user.prenom+' '+user.nom}</a>
                    </div>
                </div>


                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li className="nav-item" title="DashBoard">
                            <a href={Utils.DASHBOARD} className="nav-link active">
                                <i className="nav-icon"><DashboardOutlined /></i>
                                <p>Dashboard</p>
                            </a>
                        </li>
                        <li className="nav-item" title="Gestion des Parametres">
                            <a href={Utils.PARAMETRE} className="nav-link">
                                <i className="nav-icon">
                                    <SettingOutlined />
                                </i>
                                <p>Gestion des Parametres</p>
                            </a>
                        </li>
                        <li className="nav-item" title="Gestion des Utilisateurs">
                            <a href={Utils.USER} className="nav-link">
                                <i className="nav-icon"><UserOutlined /></i>
                                <p>Gestion des Utilisateurs</p>
                            </a>
                        </li>
                        <li className="nav-item" title="Gestion des Denonciations">
                            <a href={Utils.DENONCIATION} className="nav-link">
                                <i className="nav-icon"><GlobalOutlined /></i>
                                <p>Gestion des Denoncia..</p>
                            </a>
                        </li>
                        <li className="nav-item" title="Gestion des Groupes">
                            <a href={Utils.GROUPE} className="nav-link">
                                <i className="nav-icon"><GroupOutlined /></i>
                                <p>Gestion des Groupes</p>
                            </a>
                        </li>
                        <li className="nav-item" title="Gestion des Articles">
                            <a href={Utils.ARTICLE} className="nav-link">
                                <i className="nav-icon"><FileTextOutlined /></i>
                                <p>Gestion des Articles</p>
                            </a>
                        </li>
                        <li className="nav-item" title="Gestion des Temoignages">
                            <a href={Utils.TEMOIGNAGE} className="nav-link">
                                <i className="nav-icon"><FileTextOutlined /></i>
                                <p>Gestion des Temoign..</p>
                            </a>
                        </li>
                        <li className="nav-item" title="Gestion des Centres Partenaires">
                            <a href={Utils.CP} className="nav-link">
                                <i className="nav-icon"><BuildOutlined /></i>
                                <p>Gestion des Centres P...</p>
                            </a>
                        </li>
                        <li className="nav-item" title="Gestion des Missions">
                            <a href={Utils.MISSION} className="nav-link">
                                <i className="nav-icon"><HeatMapOutlined /></i>
                                <p>Gestion des Missions</p>
                            </a>
                        </li>
                        <li className="nav-item" title="Gestion des Valeurs">
                            <a href={Utils.VALEUR} className="nav-link">
                                <i className="nav-icon"><BuildOutlined /></i>
                                <p>Gestion des Valeurs</p>
                            </a>
                        </li>
                        <li className="nav-item" title="Gestion des Quizz">
                            <a href={Utils.QUIZZ} className="nav-link">
                                <i className="nav-icon"><QuestionCircleOutlined /></i>
                                <p>Gestion des Quizz</p>
                            </a>
                        </li>
                        <li className="nav-item" title="Gestion des Lessons">
                            <a href={Utils.ELEARNING} className="nav-link">
                                <i className="nav-icon"><ReadOutlined /></i>
                                <p>Gestion des Lessons</p>
                            </a>
                        </li>
                        <li className="nav-item" title="Gestion des Lessons">
                            <a href={Utils.THEME} className="nav-link">
                                <i className="nav-icon"><ReadOutlined /></i>
                                <p>Gestion des Themes</p>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    );
}