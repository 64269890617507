import React, {useEffect, useState} from "react";
import {Button, Space, Tag, Tooltip} from "antd";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {InfoCircleFilled} from "@ant-design/icons";
import BreadCom from "../../../components/breadCom/BreadCom";
import TableTemplate from "../../../components/table";

export default function ListDenonciation() {
    const {accessToken} = useSelector(state => state.authUser);
    const [loading, setLoading] = useState(true);
    const nav = useNavigate();
    const [denonciations, setDenonciation] = useState([]);
    useRole(['ROOT', 'SUPERADMIN']);
    useTitle('Liste des Denonciation');
    const navigate = (link) => {
        nav(link);
    }

    const fetchDenonciations = () => {
        setLoading(true);
        axiosInstance(accessToken).get('denonciation').then(
            (response) => {
                setDenonciation(response.data);
                setLoading(false);
            }
        ).catch((error) => {
            setLoading(false);
        });
    }
    useEffect(() => {
        fetchDenonciations();
    }, []);
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Auteur',
            key: 'author',
            render: (_, record) => (
                <>
          <span>
            {record.utilisateur == null ? "Anonymous" : record.utilisateur.nom + ' ' + record.utilisateur.prenom}
          </span>
                </>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return (
                    <>
                        <Space>
                            <Tooltip title={"Detail sur la denonciation"}>
                                <Button type="primary" shape="circle"
                                        onClick={() => navigate('/admin/denonciation/' + record.id)}>
                                    <i aria-hidden="true"><InfoCircleFilled /></i>
                                </Button>
                            </Tooltip>
                        </Space>
                    </>
                )
            }
        },
    ];

    return(
        <>
            <BreadCom name={"Liste des Denonciations"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Liste des Denonciations
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <TableTemplate columns={columns} loading={loading} addBTN={null} datas={denonciations} />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}