import { Select } from 'antd';
import React from 'react';

const FontFamilyPicker = ({ onChange }) => {
  const fontOptions = ['Arial', 'Georgia', 'Helvetica', 'Times New Roman', 'Verdana'];

  const handleFontChange = (selectedFont) => {
    if (onChange) {
      onChange(selectedFont);
    }
  };

  return (
    <div>
      <Select onChange={(e) => handleFontChange(e)}>
        {fontOptions.map((font) => (
          <Select.Option key={font} >
            {font}
          </Select.Option >
        ))}
      </Select>
    </div>
  );
};

export default FontFamilyPicker;
