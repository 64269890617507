import React, {useEffect, useState} from "react";
import {Button, Space, Tag, Tooltip} from "antd";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {useRole, useTitle} from "../../../components/hook";
import {EditFilled, InfoCircleFilled} from "@ant-design/icons";
import BreadCom from "../../../components/breadCom/BreadCom";
import TableTemplate from "../../../components/table";
import AddGroup from "../add/AddGroup";

export default function ListGroup() {
    const {accessToken} = useSelector(state => state.authUser);
    const [loading, setLoading] = useState(true);
    const nav = useNavigate();
    const [group, setGroup] = useState([]);
    const items = [];
    useRole(['ROOT', 'SUPERADMIN']);
    useTitle('Liste des Groupes');
    const navigate = (link) => {
        nav(link);
    }
    const fetchGroupes = () => {
        setLoading(true);
        axiosInstance(accessToken).get('api/groupes').then(
            (response) => {
                setGroup(response.data);
                setLoading(false);
            }
        ).catch((error) => {
            setLoading(false);
        });
    }
    useEffect(() => {
        fetchGroupes();
    }, []);
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            key: 'name',
            render: (_, record) => (
                <>
          <span>
            {record.name}
          </span>
                </>
            ),
        },
        {
            title: 'Libelle',
            dataIndex: 'libelle',
            key: 'libelle',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return (
                    <>
                        <Space>
                            <Tooltip title={"Detail sur le groupe"}>
                                <Button type="primary" shape="circle"
                                        onClick={() => navigate('/admin/group/' + record.id)}>
                                    <i aria-hidden="true"><InfoCircleFilled /></i>
                                </Button>
                            </Tooltip>
                            <Tooltip title={"Mise a jour du groupe"}>
                                <Button type="primary" shape="circle"
                                        onClick={() => navigate('/admin/group/update/' + record.id)}>
                                    <i aria-hidden="true"><EditFilled /></i>
                                </Button>
                            </Tooltip>
                        </Space>
                    </>
                )
            }
        },
    ];

    return(
        <>
            <BreadCom name={"Liste des Groupes"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Liste des Groupes
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <TableTemplate columns={columns} loading={loading} addBTN={<AddGroup />} datas={group} />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}