import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Button, DatePicker, Form, Input, Modal, Select, Space, Spin} from "antd";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {toastClick} from "../../../components/toast/CustomToast";
import {PlusCircleFilled} from "@ant-design/icons";

export default function AddUser(){
    const {accessToken} = useSelector(state => state.authUser);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const validateAge = (_, date) => {
        if (date && date.isAfter()) {
            return Promise.reject('La date de naissance ne peut pas être dans le futur.');
        }

        const age = date ? new Date().getFullYear() - date.year() : 0;
        if (age < 15) {
            return Promise.reject('L\'âge doit être supérieur ou égal à 15 ans.');
        }

        return Promise.resolve();
    };
    const dateFormat = 'DD-MM-YYYY';
    const [form] = Form.useForm();
    const [group, setGroup] = useState([]);
    const openModal =()=>{
        form.resetFields();
        setLoading(false);
        setModalAddOpen(true);
    }
    const fetchGroupes = () => {
        axiosInstance(accessToken).get('api/groupes')
            .then(
                response=>{
                    setGroup(response.data);
                }
            );

    };
    useEffect(() => {
        fetchGroupes();
    }, []);
    const onSubmit = (values) => {
        setLoading(true);
        const dat =values;
        let id = 0;
        id=values.groupe;
        for (let g in group){
            if(group[g].id === id)
                dat.groupe = group[g];
        }
        axiosInstance(accessToken).post(
            "user/admin",
            dat,
        ).then(
            (result)=> {
                toastClick('User '+dat.prenom+' '+dat.nom+' Create','success');
                form.resetFields();
                setLoading(false);
                setTimeout(
                    ()=>{
                        window.location.reload();
                        setModalAddOpen(false);
                    },5000
                )
            }).catch((result)=> {
            toastClick(JSON.stringify(result),'error');
            setLoading(false);
        });
    };

    return(
        <>
            <Button style={{float: "right"}}  onClick={openModal} >
                <i aria-hidden="true"><PlusCircleFilled /></i>Ajouter
            </Button>
            <Modal
                title="Creation d'un utilisateur"
                style={{
                    top: 20,
                }}
                width={1000}
                open={modalAddOpen}
                maskClosable={false}
                closable={false}
                footer={[]}
            >
                <Spin spinning={loading}>
                    <Form onFinish={onSubmit} form={form}>
                        <Form.Item
                            label="Nom"
                            name="nom"
                            rules={
                                [
                                    { required: true, message: 'Veuillez entrer le nom' },
                                    {type:"string", message:"Entrer un nom valide !"}
                                ]}
                            hasFeedback>
                            <Input placeholder="entrer le nom"/>
                        </Form.Item>
                        <Form.Item
                            label="Prénom"
                            name="prenom"
                            rules={
                                [
                                    { required: true, message: 'Veuillez entrer le nom' },
                                    {type:"string", message:"Entrer un nom valide !"}
                                ]}
                            hasFeedback>
                            <Input placeholder="entrer le Prenom"/>
                        </Form.Item>
                        <Form.Item
                            label="Date de Naissance/Creation"
                            name="ddn"
                            rules={
                                [
                                    { required: true, message: 'Veuillez entrer la Date de Naissance/Creation' },
                                    { validator: validateAge }
                                ]}
                            hasFeedback>
                            <DatePicker
                                format={dateFormat}
                                picker="date"
                                placeholder="Choisir la date de Naissance / Creation"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Lieu de Naissance/Siege Social"
                            name="lieu"
                            rules={
                                [
                                    { required: true, message: 'Veuillez entrer le Lieu de Naissance/Siege Social' },
                                    {type:"string", message:"Entrer une date valide !"}
                                ]}
                            hasFeedback>
                            <Input placeholder="entrer le Lieu de Naissance/Siege Social"/>
                        </Form.Item>
                        <Form.Item
                            label="Sexe"
                            name="sexe">
                            <Select placeholder="Choisir le genre">
                                <Select.Option value="HOMME">HOMME</Select.Option>
                                <Select.Option value="FEMME">FEMME</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Groupe"
                            name="groupe">
                            <Select placeholder="Choisir le groupe de l'utilisateur">
                                {
                                    group.map((groupe)=>{
                                        return (<Select.Option
                                            key={groupe.id}
                                            value={groupe.id}>{groupe.name}
                                        </Select.Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="N°Tel"
                            name="numero_telephone"
                            rules={[
                                { required: true, message: 'Veuillez entrer le n°tel' },
                                {type:"string", message:"Entrer le numéro de téléphone!"},
                                {len:9,message:"Veillez saisir un numéro de téléphone valide !"}
                            ]}
                            hasFeedback>
                            <Input placeholder="entrer n°Tel"/>
                        </Form.Item>
                        <Form.Item
                            label="email"
                            name="email"
                            rules={[
                                { required: true, message: 'Veuillez entrer email' },
                                {type:"email", message:"Entrer une adresse mail valide !"}
                            ]}
                            hasFeedback>
                            <Input placeholder="entrer l'email"/>
                        </Form.Item>
                        <Form.Item
                            label="Adresse"
                            name="location"
                            hasFeedback>
                            <Input placeholder="Saisir votre adresse" />
                        </Form.Item>
                        <Form.Item>
                            <Space style={{float:'right'}}>
                                <Button type='primary' htmlType='submit' disabled={loading}>
                                    Submit
                                </Button>
                                <Button danger  disabled={loading}  onClick={() => setModalAddOpen(false)} >
                                    <i className="fa fa-times" aria-hidden="true"></i> cancel
                                </Button>

                            </Space>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    );

}